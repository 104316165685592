import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function ChristmasLighting() {
  return (
    <Layout pageTitle="Christmas Lighting">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Christmas Lighting - USI Projects</title>
        <meta name="description" content="USI worked in collaboration with Belfast City Council and Lumiere Productions to create an enchanted garden in the grounds of Belfast City Hall for Christmas."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-xmaslighting">

       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Christmas Lighting</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-primarymaroon">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">USI worked
                  in collaboration with Belfast City Council and Lumiere Productions to create an enchanted garden in
                  the grounds of Belfast City Hall for Christmas 2020. The project was designed to provide an engaging
                  and immersive experience for the people of Belfast to celebrate Christmas in absence of the city's
                  Christmas markets, whilst following the Covid regulations at the time.

                </p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed   ">
                 Communities<br/>Culture<br/>
                  Tourism<br/>Technology<br/>Lighting
                </p>
                  <p class="text-2xl font-bold uppercase  ">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed   ">Concept Design<br/>
                    Stakeholder Engagement<br/>Technical Design<br/>Procurement<br/>Project Management & Installation</p>
                  <p class="text-2xl font-bold uppercase   ">Location</p>
                  <p class="my-4 text-gray-500 leading-relaxed   "> Belfast</p>
              </div>
            </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-3/4 py-4 xl:px-32 mb-4 md:mb-0">

             <img data-scroll data-scroll-speed="1" src="https://ik.imagekit.io/usi/project-pages/neon_set_up-1_1__1__uclU1CKjCxDT.png" alt=""/>



              </div>

              <div class="w-full xl:w-1/4 py-4 mb-4 md:mb-0">


                <img data-scroll data-scroll-speed="4" class="" src="https://ik.imagekit.io/usi/project-pages/2020-11-19-115633152_1_ODWDBZ-qW.png"/>

              </div>
            </div>
          </section>
        </div>
      </section>



      <div id="image">
        <img data-scroll data-scroll-speed="2" data-scroll-target="#image" class="w-full md:pl-20 md:pr-20"
          src="https://ik.imagekit.io/usi/project-pages/Screenshot_2020-11-26_at_13.05_UsZCU9hdj.png" alt=""/>
      </div>





      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/Screenshot_2020-11-26_at_13.05__1__Vd39f3weF.png"></img>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-black">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../resilience" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
